var render = function render(){var _vm=this,_c=_vm._self._c;return _c('hcc-card',{staticClass:"custom-card",attrs:{"color":"white"}},[_c('div',{staticClass:"flex-container align-middle align-justify item-card"},[_c('div',{staticClass:"column-left"},[_c('p',{staticClass:"card__title"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"card__description"},[_vm._v(" "+_vm._s(_vm.text)+" ")])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.disabledMessage ?
        _vm.disabledMessage :
        _vm.$t('configuration.options.error-empty-msg'),
      visible: _vm.disabled,
      trigger: 'hover',
      placement: 'left',
    }),expression:"{\n      content: disabledMessage ?\n        disabledMessage :\n        $t('configuration.options.error-empty-msg'),\n      visible: disabled,\n      trigger: 'hover',\n      placement: 'left',\n    }"}],staticClass:"column-right"},[_c('hcc-toggle-switch',{staticClass:"switch margin-0 large",attrs:{"name":_vm.name,"disabled":_vm.disabled},on:{"change":function($event){return _vm.$emit('change', _vm.valueToggle)}},model:{value:(_vm.valueToggle),callback:function ($$v) {_vm.valueToggle=$$v},expression:"valueToggle"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }